<template>
  <v-dialog v-model="dialogForm" max-width="500">
    <template v-slot:activator="{}">
      <v-btn
        icon
        elevation="0"
        style="z-index: 10"
        class="mr-1 mr-md-0"
        @click="openForm()"
      >
        <v-icon large>mdi-plus-circle</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>{{ $t("new", { name: $tc("discount", 1) }) }}</v-card-title>
      <div class="close">
        <v-btn icon @click="dialogForm = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <form
        action
        data-vv-scope="discount-form"
        @submit.prevent="validateForm('discount-form')"
      >
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0">
              <label v-text="$t('discounts.code')"></label>
              <v-text-field
                outlined
                hide-details
                dense
                v-model="discount.code"
                data-vv-validate-on="blur"
                v-validate="'required'"
                :error-messages="errors.collect('discount-form.code')"
                data-vv-name="code"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0" md="6">
              <label v-text="$t('discounts.type')"></label>

              <v-select
                :items="types"
                v-model="discount.type"
                outlined
                hide-details
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" class="py-0" md="6">
              <label v-text="$tc('discount')"></label>
              <v-text-field
                outlined
                hide-details
                dense
                v-model="discount.discount"
                data-vv-validate-on="blur"
                v-validate="'required'"
                :error-messages="errors.collect('discount-form.discount')"
                data-vv-name="discount"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0" md="6">
              <label v-text="$t('discounts.max_redemptions')"></label>
              <v-text-field
                outlined
                hide-details
                dense
                v-model="discount.max_redemptions"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0" md="6">
              <label v-text="$t('discounts.redeem_by')"></label>

              <v-menu
                ref="menu"
                v-model="menu"
                transition="scale-transition"
                offset-y
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    hide-details
                    dense
                    class="filters"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    @click:clear="discount.redeem_by = null"
                    :value="
                      discount.redeem_by
                        ? $d(new Date(discount.redeem_by), 'date2digits')
                        : ''
                    "
                    ><template v-slot:prepend-inner>
                      <!--Aqui va el v-icon -->
                      <v-icon
                        small
                        class="pt-0 pb-1"
                        style="margin-top: 5px"
                        :color="'primary'"
                        >$appointments</v-icon
                      >
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  first-day-of-week="1"
                  v-model="discount.redeem_by"
                  no-title
                  scrollable
                  color="primary"
                  @input="menu = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>

          <v-btn
            outlined
            @click="
              dialogForm = false;
              files = [];
            "
            style="height: 25px; width: 100px"
            elevation="0"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            type="submit"
            style="
              width: 100px !important;
              color: #363533;
              height: 25px;
              padding-top: 10px !important;
            "
            elevation="0"
          >
            <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
            {{ $t("save", { name: $t("") }) }}
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import { debounce } from "lodash";
export default {
  name: "NewDiscount",
  props: ["subservice"],
  data() {
    return {
      dialogForm: false,
      items: [],
      total: null,
      options: {},
      filters: {},
      discount: {},
      menu: false,

      types: [
        { text: "%", value: "percent" },
        { text: "€", value: "amount" },
      ],
    };
  },

  methods: {
    openForm() {
      this.discount = {};
      this.dialogForm = true;
    },
    ...mapActions("discounts", [
      "getDiscounts",
      "addDiscount",
      "updateDiscount",
    ]),

    async validateForm(scope) {
      this.errors.clear();
      let result = await this.$validator.validateAll(scope);
      if (result) {
        console.log(this.discount, this.subservice.id);
        this.discount.sub_artist_type_id = this.subservice.id;
        this.discount.artist_type_id = this.subservice.parent_id;
        this.addDiscount(this.discount).then((response) => {
          if (response.success) {
            this.$alert(this.$t("save_ok"));
            this.dialogForm = false;
            this.$emit("update");
          }
        });
      }
    },
  },
};
</script>
